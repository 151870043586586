<template>
  <div>
    <!-- toggle -->
    <iq-card class="iq-card">
      <tab-nav id="pills-tab" class="
          nav nav-pills
          d-flex
          align-items-center
          justify-content-center
          profile-feed-items
          p-0
          m-0
        ">
        <!-- <tab-nav-items class="col-sm-4 p-0" :active="true" href="#balance" id="pills-feed-tab" ariaControls="pills-home"
          role="tab" :ariaSelected="true" title="Top-up balance" @click.native="handleChangeType(1)" /> -->
        <tab-nav-items class="col-sm-6 p-0" :active="true" id="pills-feed-tab" href="#diamonds"
          ariaControls="pills-profile" role="tab" :ariaSelected="true" title="Diamonds"
          @click.native="handleChangeType(3)" />
        <tab-nav-items class="col-sm-6 p-0" :active="false" id="pills-feed-tab" href="#reward"
          ariaControls="pills-contact" role="tab" :ariaSelected="false" title="Reward Points"
          @click.native="handleChangeType(2)" />
      </tab-nav>
    </iq-card>

    <b-col sm="12" class="p-0">
      <div class="tab-content">
        <!-- <tab-content-item :active="false" id="balance" aria-labelled-by="pills-friend-tab">
          <div class="iq-card-body p-0">
            <Balance ref="balance" />
          </div>
        </tab-content-item> -->
        <tab-content-item :active="true" id="diamonds" aria-labelled-by="pills-friend-tab">
          <div class="iq-card-body p-0">
            <Diamonds :diamonds="balanceList2" :walletData1="walletData3" :walletData2="walletData4" />
          </div>
        </tab-content-item>
        <tab-content-item :active="false" id="reward" aria-labelled-by="pills-friend-tab">
          <Reward ref="funReward" :walletData="walletData2" :pointList="pointList" />
        </tab-content-item>
      </div>
    </b-col>
  </div>
</template>

<script>
  import Balance from "./components/Balance";
  import Diamonds from "./components/Diamonds";
  import Reward from "./components/Reward";
  export default {
    data() {
      return {
        balanceList2: [],
        balanceList3: [],
        walletData: [],
        walletData2: [],
        walletData3: [],
        walletData4: [],
        pointList: []
      };
    },

    components: { Balance, Diamonds, Reward },

    mounted() {
      this.getWalletInfo(4);
    },

    methods: {
      async handleChangeType(e) {
        if (e == 3) {
          this.getWalletInfo(3);
          this.getWalletInfo(4);
        } else if (e == 2) {
          this.$refs.funReward._getPointList(1);
          this.getWalletInfo(2);
        } else {
          this.$refs.balance.getWalletInfo(1);
        }
      },
      // 余额-->钱包流水
      getWalletInfo(type) {
        this.$http
          .walletAllInfo({
            type: type,
          })
          .then((res) => {
            // console.log('wellat', res);
            if (type == 4) {
              this.balanceList2 = res.data.list;
            } else if (type == 2) {
              this.pointList = res.data.list;
            } else if (type == 3) {
              this.balanceList3 = res.data.list;
            }
            res.data.user_wallet.forEach((v) => {
              if (v.type == 3) {
                this.walletData3 = v;
              } else if (v.type == 4) {
                this.walletData4 = v;
              } else if (v.type == 2) {
                this.walletData2 = v;
              }
            });
          });
      },
    },
  };
</script>
<style lang="scss" scoped>
  ::v-deep .table {
    border-color: #e9ecef;

    thead {
      background: #f4f7f9;

      th {
        border-bottom: none;
        border: none;
      }
    }
  }

  ::v-deep .el-table {
    .el-table__header-wrapper {
      .is-leaf {
        background: #f4f7f9;
        color: #3f414d;
        font-size: 14px;
        font-family: Montserrat Medium, Montserrat Medium-Medium;
        font-weight: 500;
      }
    }
  }
</style>